<template>
  <div v-if="show" class="modal" @click.self="closeModal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>

      <h2 class="mt-2">[{{component.id.value}}] {{ component.name.value }} <i v-if="displayIconFa(component)" class="fa text-dark fa-home ml-2" style="font-size: 25px"></i></h2>
      <hr class="modal-divider" />

      <div class="mb-3">
        <div class="badge badge-primary mr-2" style="font-size: 18px">{{ component.SHEET_TYPE.value }}</div>
        <div class="badge badge-info mr-2" style="font-size: 18px">{{ component.component_type.value }}</div>
        <div class="badge badge-info mr-2" v-if="component.component_subtype !== undefined" style="font-size: 18px">{{ component.component_subtype.value }}</div>
        <div class="badge badge-warning mr-2" style="font-size: 18px" v-if="component.fu_quantity">{{ component.fu_quantity.value }} {{ component.fu_measurement_unit.value }}</div>
        <div class="badge badge-secondary mr-2" style="font-size: 18px" v-if="!component.IS_MAIN_INVENTORY" >Custom</div>
        <p class="text-muted pl-2 mt-2" v-if="component.date"><span>Created at: <b>{{ (new Date(component.date.value)).toLocaleDateString('en-UK', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}</b></span></p>
      </div>

      <div v-if="simaproRuns.length > 0" class="mt-2 mb-4 justify-content-center">
        <h3><span>SimaPro runs</span></h3>
        <table class="table table-borderless compact">
          <thead class="border-0 text-white">
            <tr>
              <td class="bg-primary font-weight-bold" style="border-top-left-radius: 25px;">#</td>
              <td class="bg-primary font-weight-bold">Reference Capacity ({{simaproRuns[0].fu_measurement_unit.value}})</td>
              <td class="bg-verify"><i class="fa fa-bolt mr-2"> </i><b> P.E. Demand (GJ)</b></td>
              <td class="bg-danger" style="border-top-right-radius: 25px;" ><i class="fa fa-cloud mr-2"> </i><b> GHG Emissions (kgCO<sub>2</sub>-eq)</b></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in simaproRuns"
                :class="index % 2 === 0 ? 'bg-white col-sm ' : 'bg-row-even'">
              <td :class="getCssClass(index, null, 'left')" >{{ index + 1 }}</td>
              <td :class="getCssClass(index, null, null)"  >{{ item.fu_quantity.value.toLocaleString('en-US') }}</td>
              <td :class="getCssClass(index, 'energy', null)"  >{{ item.stage_A_embodied_pe_gj.value.toLocaleString('en-US') }}</td>
              <td :class="getCssClass(index, 'emissions', 'right')" >{{ item.stage_A_gwp_kgco2eq.value.toLocaleString('en-US') }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <h3><span>Details</span></h3>
      <table class="table table-details border-0" style="border: solid 1px lightgray !important;">
        <tbody>
        <tr v-for="(key, index) in renderKeys" :class="`${index % 2 !== 0 ? 'bg-row-even' : ''}`">
          <th
              :class="`w-50 h-50 border-0 text-left ${index % 2 !== 0 ? 'bg-row-even' : ''}`"
              style="border: solid 1px lightgray !important;">
            {{ component[key].text }}
          </th>
          <td
              :class="`w-50 h-50 border-0 ${index % 2 !== 0 ? 'bg-row-even' : ''}`"
              style="border: solid 1px lightgray !important;">
            {{ formatValueDetails(component[key].value) }}
          </td>
        </tr>
        <tr v-if="simaproRunKeys !== null"  v-for="(key, index) in simaproRunKeys" :class="`${index % 2 !== 0 ? 'bg-row-even' : ''}`">
          <th
              :class="`w-50 h-50 border-0 text-left ${index % 2 !== 0 ? 'bg-row-even' : ''}`"
              style="border: solid 1px lightgray !important;">
            {{ simaproRuns[0][key].text }}
          </th>
          <td
              :class="`w-50 h-50 border-0 ${index % 2 !== 0 ? 'bg-row-even' : ''}`"
              style="border: solid 1px lightgray !important;">
            {{ formatValueDetails(simaproRuns[0][key].value) }}
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, watch, computed, onMounted, onBeforeUnmount } from "vue";

const props = defineProps({
  component: {
    type: Object,
    required: true,
  },
  simaproRuns: {
    type: Array, 
    required: false,
  },
  show: {
    type: Boolean,
    required: true,
  },
});

// Create a ref for the edited component
const editedComponent = ref({ ...props.component });
const expandedIndex = ref(null); // Track which row is expanded


// An array of keys to be skipped during mass rendering because they will be displayed elsewhere.
const skipKeys = ['id', 'name', 'SHEET_TYPE', 'component_type', 'component_subtype', 'vcomponent_id', 'date',
                  'FK_lci_id', 'fu_quantity', 'fu_measurement_unit', 'simapro_runs', 'edit_path', 'delete_path',
                  'IS_MAIN_INVENTORY', 'IS_B_COMPONENT', 'replace_or_die']
const renderKeys = computed(() => {
  return Object.keys(editedComponent.value).filter(k => !skipKeys.includes(k))
})

const simaproRunKeys = computed(() => {

  if (props.simaproRuns === undefined || props.simaproRuns.length === 0 || props.component.IS_MAIN_INVENTORY)
    return null;

  return Object.keys(props.simaproRuns[0]).filter(k => !skipKeys.includes(k))
})



// Watch the component prop for changes
watch(
  () => props.component,
  (newVal) => {
    editedComponent.value = { ...newVal }; // Reset editedComponent
  },
  { immediate: true }
);

// Close modal function
const closeModal = () => {
  emit("close");
};

// Handle Escape key press to close modal
const handleEscapeKey = (event) => {
  if (event.key === 'Escape') {
    closeModal();
  }
};

// Add keydown listener on mount and remove on unmount
onMounted(() => {
  window.addEventListener('keydown', handleEscapeKey);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleEscapeKey);
});

// Format label function
const formatLabel = (key) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Returns a human-readable string to be displayed on the table. Checks several possible scenarios
 * that might return a null value or something empty.
 * @param value A value that can be anything (from a string, to a number, to a boolean).
 * @return {*|string} Table-compatible string.
 */
function formatValueDetails(value) {

  if (typeof value === "string") {

    if (value === '')
      value += '-';

    return value;
  }

  if (typeof value === "number")
    return value.toLocaleString();

  return value;
}

/**
 * Used on the header-2 title, to define if it is a -B or a -D component
 * If it is a -B component, it makes sure a fa-house icon is displayed.
 * @param component The component to be tested for -B or -D.
 * @return {*|boolean} True, if it is -B, false otherwise.
 */
function displayIconFa(component) {
  if (component.IS_B_COMPONENT === undefined) {
    return false;
  }

  return component.IS_B_COMPONENT;
}

/**
 * Determines the correct css class to be displayed in the simapro runs table
 * Several cases might occur, including:
 * + having to decide between energy, emissions and normal row.
 * + having to decide between even and odd row.
 * + having to decide wether a border should be displayed.
 *
 * All these are covered in this function, and all the correct CSS classes are returned here.
 *
 * @param index The even/odd index decision.
 * @param energy Decides the correct background. 'emissions' and 'energy' literals are allowed only.
 * @param position This is for the border rounding. 'left' and 'right' literals are allowed only.
 * @return {string}
 */
const getCssClass = (index, energy, position) => {
  let css = '';
  let evenOdd = index % 2 === 0 ? 'odd' : 'even' // i know this isn't supposed to be like that, but humor me.

  if (energy !== null) {
    css += energy === 'emissions' ? `bg-emissions-${evenOdd} ` : `bg-energy-${evenOdd} `;
  } else if (index % 2 !== 0) {
    css += 'bg-row-even ';
  }

  if (position !== null) {
    css += index === props.simaproRuns.length - 1 ? `row-rounded-${position} ` : '';

  }

  if (props.simaproRuns.length - 1 === index) css += 'border-bottom-0';

  return css;
}

const toggleRow = (index) => {
  if (expandedIndex.value === index) {
    expandedIndex.value = null; // Collapse if already open
  } else {
    expandedIndex.value = index; // Expand the clicked item
  }
};
// Expose the closeModal and formatLabel methods to the template
const emit = defineEmits();
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add some padding to prevent modal content from sticking to the edges on smaller screens */
}

.modal-content {
  max-height: 80%; /* Set max height to 80% of the viewport */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds max height */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Box shadow for depth */
  padding: 20px;
  border-radius: 10px;
  width: 60%;
  position: relative;
  transition: transform 0.2s; /* Smooth transition for hover effect */
}

h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

h3 span {
  background:#fff;
  padding:0 10px;
}

.modal-content:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.close {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
  font-size: 32px;
  color: #ff0000; /* Tomato color for close button */
  /* transition: color 0.3s; Smooth color transition */
}

.close:hover {
  color: #ff0000; /* Darker tomato on hover */
}

.modal-divider {
  border: none;
  height: 2px;
  background-color: #dcdcdc; /* Lighter color for the divider */
  margin: 4px 0; /* Space above and below the divider */
}

.text-center {
  text-align: center;
}

.mt-2 {
  margin-top: 10px; /* Margin for each entry */
}
.field-container {
  display: flex;
  flex-wrap: wrap; /* Allows the fields to wrap onto new lines */
}

.field {
  flex: 1 1 50%; /* Each field will take up 50% of the container width */
  box-sizing: border-box; /* Ensures padding/margins don't affect width */
  padding: 10px; /* Optional: Adds some space around each field */
}

/* Animation for modal */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.table tbody td {
  height: 40px;
  vertical-align: middle;
  border-bottom: 2px solid #dfe8f0;
}

.row-rounded-left {
  border-bottom-left-radius: 25px;
}

.row-rounded-right {
  border-bottom-right-radius: 25px;
}

.bg-emissions-odd {
  background-color: #fff5f5;
}

.bg-emissions-even {
  background-color: #ffe8e8;
}

.bg-energy-odd {
  background-color: #f5f5ff;
}

.bg-energy-even {
  background-color: #e8e8ff;
}

.bg-row-even {
  background-color: #f6f6f6;
}

.table {
  border-collapse: collapse;
}

.bg-row-even {
  background-color: #f8f9fa; /* Adjust based on your preferences */
}

</style>
